@import "../../../global-variable.scss";
@import "../../../patterns.scss";


.filePhotoGeneralized{
    &__button-upload-photo{
        margin: 15px auto 0 auto;
        text-align: center;
        @extend %addUploadButton;
    }
}
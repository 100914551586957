.errorNetworkConnect{
    display: none;
    text-align: center;
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    width: auto;
    padding: 1.5rem 1rem;
    animation: errorNetworkConnectAnim 0.2s forwards;

    @keyframes errorNetworkConnectAnim {
        0% {
            transform: translateY(-100%);
        }
    
        100% {
            transform: translateY(0%);
        }
    }
}

.errorNetworkConnectView{
    display: block;
}

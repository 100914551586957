@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.modalWindowAddDealerBG{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.95);
    z-index: 2031;
    .modalWindowAddDealerBG__close{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
        // background-color: $color-red;
        z-index: 2030;
        &:hover{
            .modalWindowAddDealerBG__close__button{
                opacity: 1;
            }
        }
        .modalWindowAddDealerBG__close__button{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 15px;
            fill: $color-white;
            opacity: .5;
            margin: 15px;
            transition: .2s;
        }
    }

    .modalWindowAddDealer{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // position: relative;
        // flex-wrap: wrap;
        max-width: 580px;
        width: 580px;
        // height: 280px;
        padding: 15px;
        margin: 15px;
        z-index: 2032;
        background-color: $body-color;
        border-radius: $border-radius;
        text-align: center;

        position: relative;
        overflow: hidden;

        &__header-name{
            font-weight: bold;
        }

        &__input-block{
            margin-top: 15px;
            position: relative;
        }


        &__input{
            width: 100%;
            background-color: $body-color;
            border-radius: 10px;
            border: 1px solid $color-blue;
            height: 44px;
            padding: 8px 45px 8px 8px;
            transition: .2s;
            &:hover{
                box-shadow:  0px 0px 5px 1px $color-blue; 
            }
            &:focus{
                box-shadow: 0 0 5px 1px $color-blue;
                outline: 0 !important;
              }
            &:active{
                box-shadow: 0 0 0 2px transparent !important;
                outline: 0 !important;
            }
        }

        &__button-block{
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;
        }

        &__create-button{
            height: $height-button;
            width: 50%;
            margin-left: 30px;
            @extend %blueButton;
        }
        &__cancel-button{
            height: $height-button;
            // width: 50%;
            @extend %cancelButton;
        }

        &__input-password-block{
            position: relative;
            margin-top: 15px;
        }

        &_error{
            // box-shadow: 0 0 0 2px rgba($color-red, 1);
            border-radius: $border-radius;
            animation: form-error__FF__box 2s forwards;
            &::after{
                content: attr(data-error);
                position: absolute;
                // width: 35%;
                height: 100%;
                background-color: rgba($color-red, 1);
                top: 0;
                right: 0;
                pointer-events: none;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                overflow: hidden;
                white-space: nowrap;
                z-index: 10;
                border-radius: 0 $border-radius $border-radius 0;
                // white-space: pre-wrap;
                color: $color-white;
                animation: form-error__FF__text 2s forwards;
            }
            @keyframes form-error__FF__text {
                0%{
                    width: 0;
                }
                10% {
                    width: 50%;
                  }
                90%{
                    width: 50%;
                }
                100%{
                    width: 0;
                }
            }
            @keyframes form-error__FF__box {
                0%{
                    box-shadow: 0 0 2 px 2px rgba($color-red, 0);
                }
                10% {
                    box-shadow: 0 0 2px 2px rgba($color-red, 1);
                  }
                100%{
                    box-shadow: 0 0 2px 2px rgba($color-red, 1);
                }
                // 100%{
                //     box-shadow: 0 0 0 2px rgba($color-red, 0);
                // }
            }
        }


        &__svg{
            margin-top: 15px;
            width: 50px;
            height: 50px;
            fill: $color-green;
        }

        &__text-success{
            margin-top: 15px;
        }

        &__close-button{
            width: 100%;
            height: $height-button;
            margin-top: 25px;
            @extend %blueButton;
        }


        .showPasswordButton{
            position: absolute;
            top: 0;
            right: 0;
            // background-color: rebeccapurple;
            height: 100%;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover svg{
                fill: rgba($color-white-a, 1);
            }
            & svg{
                fill: rgba($color-white-a, .8);
                height: 20px;
                width: 20px;
                transition: .2s;
            }
        }

        .hideShowPasswordButton{
            display: none;
            opacity: 0;
            pointer-events: none;
            user-select: none;
        }


        

    }


}

$timeAnim: .35s;
$translateY: -50px;

.modalWindowAddDealerAnim-enter{
    animation: OpenModalAnim $timeAnim;
    .modalWindowAddDealer{
        animation: OpenModalTransAnim $timeAnim;
    }
}
.modalWindowAddDealerAnim-enter-active{
    animation: OpenModalAnim $timeAnim;
    .modalWindowAddDealer{
        animation: OpenModalTransAnim $timeAnim;
    }
}
.modalWindowAddDealerAnim-exit{
    animation: CloseModalAnim $timeAnim;
    .modalWindowAddDealer{
        animation: CloseModalTransAnim $timeAnim;
    }
    
}
.modalWindowAddDealerAnim-exit-active{
    animation: CloseModalAnim $timeAnim;
    .modalWindowAddDealer{
        animation: CloseModalTransAnim $timeAnim;
    }
}


@keyframes OpenModalTransAnim {
    0% {
    transform: translateY($translateY);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes CloseModalTransAnim {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY($translateY);
    }
}

@keyframes OpenModalAnim {
    0% {
    opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes CloseModalAnim {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.pacifier{
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__svg-block{
        margin-top: 15px;
        width: 70px;
        height: 50px;
        & svg{
            width: 100%;
            height: 100%;
            fill: $color-blue;
        }
    }

    &__text-block{
        margin-top: 15px;
        font-size: 24px;
        text-align: center;
    }

    &__question-block{
        & span{
            font-size: 14px;
            text-decoration: underline;
            color: $color-blue;
            transition: .2s;
            cursor: pointer;

            &:hover{
                color: rgba($color: $color-blue, $alpha: .5) ;
            }
        }
    }

    &__button-block{
        width: 100%;
        margin-top: 15px;
    }
    &__add-user{
        @extend %greenButton;
    }
}
@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.cardDealer{
    border: 2px solid $color-green;
    border-radius: 10px;
    padding: 15px 15px 5px 15px;
    width: 100%;
    max-width: 307.5px;
    margin: 15px 15px 0 0;
    overflow: hidden;

    &__dataAndRemove{
        display: flex;
        justify-content: space-between;
    }

    &__buttonAndCountPhoto{
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid $color-green;
    }
    &__email{
        font-size: 18px;
        font-weight: 600;
        text-align: left;
    }
    &__password{
        text-align: left;
    }
    &__buttonShow{
        height: $height-button;
        width: 150px;
        @extend %blueButton;
    }
    &__emailAndpassword, &__buttonAndCountPhoto{
        margin-top: 15px;
    }
    &__emailAndpassword{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__remove{
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        & svg{
            width: 15px;
            height: 15px;
            min-height: 15px;
            min-width: 15px;
            fill: $color-red;
        }
    }
    &__countPhoto{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__count{
        font-size: 18px;
        font-weight: bold;
        margin-left: 3px;
    }
    &__buttonPhoto{
        margin-top: 15px;
        height: $height-button;
        color: black;
        @extend %yellowButton;
    }
    &__nameDealer{
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -17px;
        margin-top: 15px;
        background-color: rgba($color: black, $alpha: .2);
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
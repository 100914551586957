@import "./global-variable.scss";

%panel{
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  background-color: $body-color;
  border-radius: $border-radius;
  box-shadow: 0 0 10px rgba($color: black, $alpha: .25);
}

%loginButton{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius !important;
  background-color: $color-blue;
  box-shadow: 0 0 0 transparent;
  border: 0;
  outline: 0;
  color: $color-white;
  cursor: pointer;
  transition: .2s;
  user-select: none;
  text-decoration: none;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover{
      box-shadow: 0 0 10px $color-blue !important;
      outline: 0 !important;
  }
  &:focus{
      box-shadow: 0 0 0 transparent;
      outline: 0 !important;
    }
  &:active{
      box-shadow: 0 0 0 0 transparent !important;
      outline: 0 !important;
  }
}

%blueButton{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius !important;
    background-color: $color-blue;
    box-shadow: 0 0 0 transparent;
    border: 0;
    outline: 0;
    color: $color-white;
    cursor: pointer;
    transition: .2s;
    user-select: none;
    text-decoration: none;
  
    -webkit-appearance: none;
    -moz-appearance: none;
  
    &:hover{
        box-shadow: 0 0 10px $color-blue !important;
        outline: 0 !important;
    }
    &:focus{
        box-shadow: 0 0 0 transparent;
        outline: 0 !important;
      }
    &:active{
        box-shadow: 0 0 0 0 transparent !important;
        outline: 0 !important;
    }
}

%yellowButton{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius !important;
  background-color: $color-yellow;
  box-shadow: 0 0 0 transparent;
  border: 0;
  outline: 0;
  color: $color-white;
  cursor: pointer;
  transition: .2s;
  user-select: none;
  text-decoration: none;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover{
      box-shadow: 0 0 10px $color-yellow !important;
      outline: 0 !important;
  }
  &:focus{
      box-shadow: 0 0 0 transparent;
      outline: 0 !important;
    }
  &:active{
      box-shadow: 0 0 0 0 transparent !important;
      outline: 0 !important;
  }
}

%borderWhiteButton{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius !important;
  // background-color: transparent;
  box-shadow: 0 0 0 transparent;
  border: 1px solid $color-white-a;
  outline: 0;
  color: $color-white-a;
  cursor: pointer;
  transition: .2s;
  user-select: none;
  text-decoration: none;
  padding: 0 20px;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover{
      box-shadow: 0 0 10px $color-white-a !important;
      outline: 0 !important;
  }
  &:focus{
      box-shadow: 0 0 0 transparent;
      outline: 0 !important;
    }
  &:active{
      box-shadow: 0 0 0 0 transparent !important;
      outline: 0 !important;
  }
}

%borderBlueButton{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius !important;
  // background-color: $color-green;
  border: 1px solid $color-blue;
  box-shadow: 0 0 0 transparent;
  outline: 0;
  color: black;
  cursor: pointer;
  transition: .2s;
  user-select: none;
  text-decoration: none;
  padding: 8px 20px;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover{
      box-shadow: 0 0 10px $color-blue !important;
      outline: 0 !important;
  }
  &:focus{
      box-shadow: 0 0 0 transparent;
      outline: 0 !important;
    }
  &:active{
      box-shadow: 0 0 0 0 transparent !important;
      outline: 0 !important;
  }
}


%greenButton{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius !important;
  background-color: $color-green;
  box-shadow: 0 0 0 transparent;
  border: 0;
  outline: 0;
  color: $color-white;
  cursor: pointer;
  transition: .2s;
  user-select: none;
  text-decoration: none;
  padding: 8px 20px;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover{
      box-shadow: 0 0 10px $color-green !important;
      outline: 0 !important;
  }
  &:focus{
      box-shadow: 0 0 0 transparent;
      outline: 0 !important;
    }
  &:active{
      box-shadow: 0 0 0 0 transparent !important;
      outline: 0 !important;
  }
}

%redButton{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius !important;
  background-color: $color-red-a;
  box-shadow: 0 0 0 transparent;
  border: 0;
  outline: 0;
  color: $color-white;
  cursor: pointer;
  transition: .2s;
  user-select: none;
  text-decoration: none;
  padding: 8px 20px;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover{
      box-shadow: 0 0 10px $color-red-a !important;
      outline: 0 !important;
  }
  &:focus{
      box-shadow: 0 0 0 transparent;
      outline: 0 !important;
    }
  &:active{
      box-shadow: 0 0 0 0 transparent !important;
      outline: 0 !important;
  }
}

%cancelButton{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius !important;
  // background-color: $color-blue;
  box-shadow: 0 0 0 transparent;
  border: 0;
  outline: 0;
  // color: $color-white;
  cursor: pointer;
  transition: .2s;
  user-select: none;
  text-decoration: none;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover{
      box-shadow: 0 0 0 transparent;
      outline: 0 !important;
  }
  &:focus{
      box-shadow: 0 0 0 transparent;
      outline: 0 !important;
    }
  &:active{
      box-shadow: 0 0 0 transparent;
      outline: 0 !important;
  }
}

%yellowButton{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius !important;
  background-color: $color-yellow;
  box-shadow: 0 0 0 transparent;
  border: 0;
  outline: 0;
  color: black;
  cursor: pointer;
  transition: .2s;
  user-select: none;
  text-decoration: none;
  padding: 8px 20px;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover{
      box-shadow: 0 0 10px $color-yellow !important;
      outline: 0 !important;
  }
  &:focus{
      box-shadow: 0 0 0 transparent;
      outline: 0 !important;
    }
  &:active{
      box-shadow: 0 0 0 0 transparent !important;
      outline: 0 !important;
  }
}

%addUploadButton{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius !important;
  // background-color: $color-yellow;
  box-shadow: 0 0 0 transparent;
  border: 0;
  outline: 0;
  color: $color-blue;
  cursor: pointer;
  transition: .2s;
  user-select: none;
  text-decoration: none;
  font-weight: 700;
  padding: 8px 20px;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover{
      // box-shadow: 0 0 10px $color-yellow !important;
      background-color: rgba($color: black, $alpha: .1);
      outline: 0 !important;
  }
  &:focus{
      // box-shadow: 0 0 0 transparent;
      outline: 0 !important;
    }
  &:active{
      // box-shadow: 0 0 0 0 transparent !important;
      outline: 0 !important;
  }
}
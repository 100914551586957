@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.modalWindowUserPhotoBG{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    // height: 100vh;
    background-color: rgba(0,0,0,.95);
    z-index: 1040;
    min-height: 100vh;
    min-height: -webkit-fill-available;


    
    
    .modalWindowUserPhotoBG__close{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
        // background-color: $color-red;
        z-index: 1030;
        &:hover{
            .modalWindowUserPhotoBG__close__button{
                opacity: 1;
            }
        }
        .modalWindowUserPhotoBG__close__button{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 15px;
            fill: $color-white;
            opacity: .5;
            margin: 15px;
            transition: .2s;
        }
    }

    .modalWindowUserPhoto{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // position: relative;
        // flex-wrap: wrap;
        max-width: 580px;
        width: 580px;
        height: calc(100vh - 116px);
        padding: 15px;
        margin: 15px;
        z-index: 1032;
        background-color: $body-color;
        border-radius: $border-radius;
        text-align: center;

        position: relative;
        overflow: hidden;

        &__header-name{
            font-weight: bold;
        }

        &__email-and-count-photo-block{
            margin-top: 15px;
        }
        &__email{
            font-weight: bold;
        }
        &__count-number{
            font-weight: bold;
            margin-left: 5px;
        }

        &__photo-block{
            overflow-y: auto;
            height: 100%;
        }

        &__button-block{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            min-height: 44px;
        }
        &__delete-all-photo-button{
            width: 100%;
            margin-right: 15px;
            height: $height-button;
            @extend %redButton;
        }
        &__close-button{
            width: 100%;
            height: $height-button;
            padding: 8px 20px;
            // margin-left: 15px;
            @extend %blueButton;
        }       

    }


}

$timeAnim: .35s;
$translateY: -50px;

.modalWindowUserPhotoAnim-enter{
    animation: OpenModalAnim $timeAnim;
    .modalWindowUserPhoto{
        animation: OpenModalTransAnim $timeAnim;
    }
}
.modalWindowUserPhotoAnim-enter-active{
    animation: OpenModalAnim $timeAnim;
    .modalWindowUserPhoto{
        animation: OpenModalTransAnim $timeAnim;
    }
}
.modalWindowUserPhotoAnim-exit{
    animation: CloseModalAnim $timeAnim;
    .modalWindowUserPhoto{
        animation: CloseModalTransAnim $timeAnim;
    }
    
}
.modalWindowUserPhotoAnim-exit-active{
    animation: CloseModalAnim $timeAnim;
    .modalWindowUserPhoto{
        animation: CloseModalTransAnim $timeAnim;
    }
}


@keyframes OpenModalTransAnim {
    0% {
    transform: translateY($translateY);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes CloseModalTransAnim {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY($translateY);
    }
}

@keyframes OpenModalAnim {
    0% {
    opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes CloseModalAnim {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
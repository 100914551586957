@import "../../global-variable.scss";
@import "../../patterns.scss";


.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.welcome-page {
    // background-color: rebeccapurple;

    // common styles start

    &__faceBlock{
        background-color: $body-color;
        width: 100%;
        height: 180px;
        position: absolute;
        top: 0;
    }

    &__section {
        padding: 50px 0;
    }

    &__section:nth-child(odd) {
        background-color: $body-color;
    }

    // &__section:nth-child(3) {
    //     padding-bottom: 200px;
    // }

    // &__section:nth-child(4) {
    //     height: 800px;
    // }

    &__section:last-child {
        background-color:#fafafa;
    }

    &__header {
        font-weight: 700;
        color: #243141;
    }

    &__title-h1 {
        margin: 0;
        text-align: center;
        font-size: 2.6rem;
    }

    &__title-h2 {
        font-size: 1.8rem;
    }

    &__title-h3 {
        font-size: 1.4rem;
    }

    &__title-h4 {
        font-size: 1.2rem;
    }

    &__description-text {
        font-size: 1rem;
        font-weight: 500;
        color: #8A94A6;
    }

    // common styles end

    // Main description start

    &__main-description {
        display: flex;
        flex-direction: column;
        // align-items: center;
        // align-items: stretch;
    }

    &__sub-title {
        margin: 20px auto 50px auto;
        max-width: 450px;
        text-align: center;
        font-size: 1.2em;
    }

    &__image-block{
        max-width: 635px;
        width: 100%;
        height: 100%;
        margin: 0 auto;

        & img {
            width: 100%;
            height: 100%;
        }
    }
    
    // Main description end

    // How work start

    // &__howWork {
    //     display: flex;
    //     justify-content: space-around;
    // }
    // &__howWork::after {
    //     content: "";
    //     width: 700px;
    //     height: 600px;
    //     background-image: url(./img/man.png);
    //     background-size: 50%;
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     // background-color: red;
    //     position: absolute;
    //     margin: -200px 0 0 500px;
    // }

    &__howWork-title {
        margin: 0;
    }
    
    &__howWork-description {
        max-width: 450px;
        margin: 20px 0 0 0;
        font-size: 1em;
    }

    // How work end

    // Advantages start

    &__advantages {
        position: relative;

        &-man{
            position: absolute;
            top: -218px;
            right: 105px;
            z-index: 1;
        }
    }

    &__advantages-block{
        background-color: white;
        position: relative;
    }

    &__advantages-list {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    &__advantages-item {
        display: flex;
        align-items: center;
        position: relative;
        max-width: 600px;
        width: 600px;
        height: 250px;
        padding: 15px;
        border-radius: $border-radius;
        list-style: none;
        background-color: #243141;
        z-index: 1;

        &:nth-child(1) {
            align-self: flex-start;
        }

        &:nth-child(2) {
            align-self: center;
            margin-top: 20px;

            &::before {
                content: url(../media/welcomePage/arrow.svg);
                // background-color: rebeccapurple;
                position:absolute;
                max-width: 110px;
                top: 75px;
                margin-left: -200px;
            }
        }

        &:nth-child(3) {
            align-self: flex-end;
            margin-top: 20px;

            &::before {
                content: url(../media/welcomePage/arrow.svg);
                    // background-color: rebeccapurple;
                    position:absolute;
                    max-width: 110px;
                    top: 75px;
                    margin-left: -200px;
            }
        }
    }

    // &__advantages-title {
    //     font-size: 1.4rem;
    //     margin-bottom: 50px;
    // }

    
    &__advantages-item-content {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__advantages-count {
        color: $color-white;
        font-size: 30px;
        font-weight: bold;
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 15px;
    }

    &__advantages-image-block {
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: red;
        max-width: 200px;
        max-height: 200px;
        margin-right: 15px;
        & img{
            width: 100%;
            height: auto;
        }
    }

    // &__advantages-item:nth-child(1) .welcome-page__advantages-item-content::before {
    //     content: url(../media/welcomePage/1.png);
    //     // background-image: url(../media/welcomePage/1.png);
    // }

    // &__advantages-item:nth-child(2) .welcome-page__advantages-item-content::before {
    //     content: url(../media/welcomePage/2.png);
    // }

    // &__advantages-item:nth-child(3) .welcome-page__advantages-item-content::before {
    //     content: url(../media/welcomePage/3.png);
    // }

    &__advantages-item-title {
        color: $color-white;
    }

    // Advantages end

    // FAQ start

    &__faq-topics {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 50px;
    }

    &__faq-topics-container {
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
    }

    // &__faq-topics-col {
    //     display: flex;
    //     flex-direction: column;
    // }
    &__section_pt0{
        padding-top: 0;
    }

    &__faq-topic {
        position: relative;
        display: flex;
        flex-direction: column;
        // height: min-content;
        overflow: hidden;
        max-height: 54px;
        transition: max-height 0.15s ease-out;
        background-color: #fefefe;
        border-radius: 10px;
        // padding: 16px 0;
        padding: 15px 15px 15px 50px;
        border: 1px solid #dfe5ff;
        // margin: 15px;
        cursor: pointer;
        margin-top: 15px;
        max-width: 652px;
        width: 100%;
        user-select: none;
        &:nth-child(odd){
            margin-right: 15px;
        }
    }

    &__topic-question {
    //    font-weight: 700;
       color: #243141;
       margin-bottom: 0;

    }
    &__topic-question::before {
        content: '\002B';
        // margin: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        color: #546EE8;
        border: 1px solid #546EE8;
        border-radius: 50%;
        line-height: 100%;
        font-size: 15px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        transition: max-height 0.15s ease-out;

        position: absolute;
        left: 15px;
    }
    &__topic-question-show::before {
        content: '\2212';
        background-color: #546EE8;
        color: #efefef;
        transition: all 0.25s ease-in;
    }

    &__topic-answer {
        // padding: 20px 60px;
        margin-top: 15px;
        user-select: none;
        color: #8a94a6;
    }

    &__faq-topic-show {
        max-height: 500px !important;
        border: 0;
        box-shadow: 0 0 30px rgba(0,0,0,0.1);
        transition: max-height 0.25s ease-in;
        transition: all 0.25s ease-in;
    }

    // FAQ end

    // Get started start

    &__getstarted {
        display: flex;
        flex-direction: column;
        // align-items: center;
        padding: 50px 20px;
        border-radius: 10px;
        background-color: #546EE8;
        // margin-bottom: 100px;
        text-align: center;
        position: relative;
        // align-items: stretch;
        &-roc{
            height: 198px;
            width: 119px;
            position: absolute;
            top: 23px;
            right: 15px;
            & img{
                width: 100%;
                height: 100%;
            }
        }
        &-cloud{
            height: 198px;
            width: 179px;
            position: absolute;
            top: 23px;
            left: 15px;
            & img{
                width: 100%;
                height: 100%;
            }
        }
    }

    &__getstarted-title {
        color: $color-white;
    }

    &__getstarted-text {
        color: $color-white;
    }

    &__getstarted-link {
        margin: 20px auto 0 auto;
        font-size: 1.2rem;
        padding: 5px 20px;
        color: $color-white;;
        border: 1px solid $color-white;
        border-radius: 20px;
        text-decoration: none;
        transition: .2s;
        cursor: pointer;
    }

    &__getstarted-link:hover {
        background-color: $body-color;
        color: #546EE8;
        box-shadow: 0 0 10px $body-color;
    }
    
    // Get started end

    // Get started end

    &__footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 0;
        border-top: 1px solid $color-white-a;

        & svg{
            width: 150px;
            height: 30px;
            min-width: 150px;
        }
        &-text{
            font-size: 14px;
            color: $color-white-a;
            margin-left: 15px;
        }
    }

}
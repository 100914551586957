@import "../../global-variable.scss";
@import "../../patterns.scss";

.headerFluid{
    // background-color: rebeccapurple;
    // height: 60px;
    // position: fixed;

    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        // padding: 5px 0;
        // background-color: red;
        // height: 100%;

        &__buttonBlock{
            display: flex;
            // flex: 1;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            height: 60px;
        }

        &__logo{
            height: 100%;
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            & svg{
                max-width: 150px;
                max-height: 30px;
                height: 100%;
                width: 100%;
            }
        }

        &__loginButton{
            height: $height-button;
            width: 150px;

            @extend  %loginButton;
        }

        &__logoutButton{
            height: $height-button;
            
            // width: 150px;

            @extend  %borderWhiteButton;

            & svg{
                width: 15px;
                height: 15px;
                min-width: 15px;
                min-height: 15px;
                fill: $color-white-a;
                margin-right: 5px;
            }
        }

        &__removeAllPhotos{
            @extend  %borderWhiteButton;
            margin-right: 20px;

            & svg{
                width: 15px;
                height: 15px;
                min-width: 15px;
                min-height: 15px;
                fill: $color-white-a;
                margin-right: 5px;
            }
        }

        &__authUserName{
            display: flex;
            align-items: center;
            margin-right: 15px;
        }
       


    }

}
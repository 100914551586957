@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.panelUser{
    @extend %panel;

    &__header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__button-update-photos{
        width: 100%;
        margin-top: 15px;
        @extend %greenButton;

        & svg{
            width: 15px;
            height: 15px;
            fill: $color-white;
            margin-right: 15px;
            animation: identifier 1.8s linear infinite;
            opacity: 0;
            transition: .1s;
        }

        & span{
            margin-right: 15px;
        }

        @keyframes identifier {
            0%{
                transform: rotate(0deg);
            }
            100%{
                transform: rotate(360deg);
            }
        }
    }
    &__show-spinner{
        opacity: 1 !important;
    }
    &__button-new-photo{
        margin-top: 5px;
        height: 44px;
        padding: 0 8px;
        @extend %blueButton;
    }
    &__new-photo{
        border-radius: 50%;
        background-color: $color-white;
        color: black;
        // width: 30px;
        height: 30px;
        min-width: 30px;
        display: flex;
        align-self: center;
        justify-content: center;
        margin: 0 5px;
        font-weight: bold;
        padding: 3px;
    }

    &__text-number-of-photos{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
    }

    &__number{
        font-weight: 600;
        margin-left: 5px;
    }

    &__title{
        // font-size: 18px;
        font-weight: bold;
        margin-top: 15px;
    }

}
@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.modalWindowAccuratelyRemoveBG{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.95);
    z-index: 2031;
    .modalWindowAccuratelyRemoveBG__close{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
        // background-color: $color-red;
        z-index: 2030;
        &:hover{
            .modalWindowAccuratelyRemoveBG__close__button{
                opacity: 1;
            }
        }
        .modalWindowAccuratelyRemoveBG__close__button{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 15px;
            fill: $color-white;
            opacity: .5;
            margin: 15px;
            transition: .2s;
        }
    }

    .modalWindowAccuratelyRemove{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // position: relative;
        // flex-wrap: wrap;
        max-width: 580px;
        width: 580px;
        // height: 280px;
        padding: 15px;
        margin: 15px;
        z-index: 2032;
        background-color: $body-color;
        border-radius: $border-radius;
        text-align: center;

        position: relative;
        overflow: hidden;

        &__header-name{
            font-weight: bold;
        }

        &__text-block{
            margin: 25px 0;
            display: flex;
            flex-direction: column;
        }

        &__text-user{
            font-weight: 600;
            margin: 0 5px;
        }

        &__warning-block{
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 15px;
            background-color: $color-yellow-a;
            border-radius: $border-radius;
        }
        &__warning-header{
            font-weight: bold;
        }
        &__warning-text{
            margin-top: 15px;
        }
        &__warning-button-block{
            display: flex;
            margin-top: auto;
        }
        &__ok-button{
            height: $height-button;
            width: 50%;
            margin-left: 30px;
            background-color: transparent;
            @extend %cancelButton;
        }


        &__button-block{
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;
        }

        &__change-button{
            height: $height-button;
            width: 50%;
            margin-right: 30px;
            @extend %blueButton;
        }
        &__delete-button{
            height: $height-button;
            width: 50%;
            margin-left: 30px;
            background-color: transparent;
            @extend %cancelButton;
        }
        &__cancel-button{
            height: $height-button;
            width: 50%;
            @extend %blueButton;
        }



        

    }


}

$timeAnim: .35s;
$translateY: -50px;

.modalWindowAccuratelyRemoveAnim-enter{
    animation: OpenModalAnim $timeAnim;
    .modalWindowAccuratelyRemove{
        animation: OpenModalTransAnim $timeAnim;
    }
}
.modalWindowAccuratelyRemoveAnim-enter-active{
    animation: OpenModalAnim $timeAnim;
    .modalWindowAccuratelyRemove{
        animation: OpenModalTransAnim $timeAnim;
    }
}
.modalWindowAccuratelyRemoveAnim-exit{
    animation: CloseModalAnim $timeAnim;
    .modalWindowAccuratelyRemove{
        animation: CloseModalTransAnim $timeAnim;
    }
    
}
.modalWindowAccuratelyRemoveAnim-exit-active{
    animation: CloseModalAnim $timeAnim;
    .modalWindowAccuratelyRemove{
        animation: CloseModalTransAnim $timeAnim;
    }
}


@keyframes OpenModalTransAnim {
    0% {
    transform: translateY($translateY);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes CloseModalTransAnim {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY($translateY);
    }
}

@keyframes OpenModalAnim {
    0% {
    opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes CloseModalAnim {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
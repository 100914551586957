@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.panelHeader{

    &__infoBlock{
        width: 100%;
        background-color: $color-blue;
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    &__welcomeBlock{
        display: flex;
        flex-wrap: wrap;
        font-size: 24px;
        color: $color-white;
        margin-right: 10px;
    }
    &__email{
        font-weight: bold;
    }
    &__text{
        margin-right: 10px;
    }

    &__authUserName{
        display: flex;
        align-items: center;
    }
    &__countDealer{
        color: $color-white-a;
    }
    
    &__buttonAdminBlock{
        margin-top: 15px;
        display: flex;
    }
    &__buttonAddDealer{
        height: $height-button;
        @extend  %borderWhiteButton;
    }
    &__buttonAddUser{
        margin-right: 15px;
        height: $height-button;
        @extend  %greenButton;
    }
    
    
    
    &__qrCodeBlock{
        width: 120px;
        height: 120px;
        position: relative;
        cursor: pointer;
    }
    &__qrCodeBlock_null{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $color-white;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        max-width: 120px;
        background-color: $color-blue;
        // border: 1px solid rgba($color: $color-white, $alpha: .5);
        & svg{
            width: 30px;
            height: 30px;
            fill: $color-white;
        }
    }

    &_dcur{
        cursor: default;
    }

}


@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $body-color;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 9999;
    user-select: none;
    pointer-events: none;

}

$timeAnim: .35s;
$timeAnimStart: 0s;

.loadingAnim-enter{
    animation: OpenModalAnim $timeAnimStart;
}
.loadingAnim-enter-active{
    animation: OpenModalAnim $timeAnimStart;
}
.loadingAnim-exit{
    animation: CloseModalAnim $timeAnim;
}
.loadingAnim-exit-active{
    animation: CloseModalAnim $timeAnim;
}


@keyframes OpenModalAnim {
    0% {
    opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes CloseModalAnim {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.panelDealer{
    @extend %panel;

    &__title{
        font-weight: bold;
        margin-top: 15px;
    }

    &__cardUser{
        display: flex;
        flex-wrap: wrap;
    }

}
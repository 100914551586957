// :export {
//     colorPink: $color-pink;
//     colorWhite: $text-color;
//     colorWhiteA: $text-color-a;
//     colorBackground: $bg-color;
//     colorGreenSvg: $color-green-svg;
//     colorRedSvg: $color-red-svg;
//     colorGreenA: $color-green-a;
//     colorPinkSvg: $color-pink;
//     bgColor: $bg-color;
// }

$body-color: #FAFAFA;

$border-radius: 10px;
$height-button: 44px;

$color-white: #eee;
$color-white-a: #B6B6B7;
$color-red: #EE3358;
$color-red-a: #ba0f31;
$color-blue: #546EE8;
$color-blue-a: #c7cce1;
$color-green: #30CA71;
$color-yellow: #FFC107;
$color-yellow-a: #FFF3CD;

:export{
    colorBlue: $color-blue;
    colorWhite: $color-white;
}
@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.panelAdmin{
    @extend %panel;

    &__radioButton{
        border: 1px solid $color-blue;
        padding: 8px;
        border-radius: 10px;
        margin: 5px 5px 0 0;
        color: rgba($color: black, $alpha: .5);
        cursor: pointer;
        transition: .2s;
        user-select: none;



        & span{
            font-weight: bold;
            margin-left: 3px;
        }
    }

    &__title{
        // font-size: 18px;
        font-weight: bold;
        margin-top: 15px;

    }

    &__form{
        &::-webkit-scrollbar {
            height: 0px;
            background: transparent;
            -webkit-appearance: none;
            display: none;
        }
    }
}


@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.warning{
    padding: 15px;
    background-color: $color-yellow-a;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    position: fixed;
    bottom: 0;
    left: 0;

    &__text{
        width: 25%;
        padding: 5px;
    }
    &__data{
        margin: 0 3px;
        font-weight: bold;
    }
    &__button-link{
        width: 25%;
        @extend %yellowButton;
    }
    &__button-link:hover{
        color: #000000 !important;
    }
    &__button-close{
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        & svg{
            transition: .2s;
            width: 20px;
            height: 20px;
            fill: rgba($color: #000000, $alpha: .5);
        }
        &:hover svg{
            fill: rgba($color: #000000, $alpha: 1);
        }
    }
}
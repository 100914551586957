@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.filePhoto{
    border-radius: $border-radius;
    &__row{
        margin-top: 15px;
        padding: 8px;
        display: flex;
        align-items: center;
        border: 1px solid rgba($color: $color-blue, $alpha: .5) ;
        border-radius: $border-radius;
    }

    &__img{
        width: 50px;
        height: 50px;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__info-block{
        margin-left: 15px;
        width: 100%;
    }

    &__file-name{
        font-weight: bold;
        text-align: left;
    }

    &__data-and-time{
        opacity: .7;
        text-align: left;
    }

    &__button{
        height: $height-button;
        width: 150px;
        @extend %blueButton;
    }


    &_new{
        animation: newPhoto 2.0s forwards;
    }
    @keyframes newPhoto {
        0%{
            background-color: transparent;
            // box-shadow: 0;
        }
        20%{
            background-color: $color-blue-a;
            // box-shadow: 0 0 10px $color-blue-a;
        }
        80%{
            background-color: $color-blue-a;
            // box-shadow: 0 0 10px $color-blue-a;
        }
        100%{
            background-color: transparent;
            // box-shadow: 0;
        }
    }

    &__not-confirmed{
        pointer-events: none;
        user-select: none;
        opacity: .25;
    }


}
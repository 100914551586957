@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.modalWindowContactsBG{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    // height: 100vh;
    background-color: rgba(0,0,0,.95);
    z-index: 1040;
    min-height: 100vh;
    min-height: -webkit-fill-available;


    
    
    .modalWindowContactsBG__close{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
        // background-color: $color-red;
        z-index: 1030;
        &:hover{
            .modalWindowContactsBG__close__button{
                opacity: 1;
            }
        }
        .modalWindowContactsBG__close__button{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 15px;
            fill: $color-white;
            opacity: .5;
            margin: 15px;
            transition: .2s;
        }
    }

    .modalWindowContacts{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // position: relative;
        // flex-wrap: wrap;
        max-width: 675px;
        width: 675px;
        max-height: calc(100vh - 116px);
        padding: 15px;
        margin: 15px;
        z-index: 1032;
        background-color: $body-color;
        border-radius: $border-radius;
        text-align: center;

        position: relative;
        overflow: hidden;

        &__block{
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;
            overflow: auto;
        }

        &__header-name{
            font-weight: bold;
        }


        &__info-block{
            display: flex;
            align-items: center;
            flex-direction: column;
            // background-color: rebeccapurple;
            width: 100%;
            max-width: 204px;
            padding: 15px;
            border-radius: 10px;
            border: 1px solid $color-blue;
            margin-right: 15px;
            overflow: auto;

            & svg{
                width: 30px;
                height: 30px;
                fill: $color-blue;
            }

            &:last-child{
                margin-right: 0;
            }
        }
        &__phone-text, &__email-text, &__telegram-text{
            display: flex;
            flex-direction: column;
            margin-top: 15px;

            & a{
                margin-top: 5px;
                text-decoration: none;
                color: black;
                transition: .2s;
                
                &:hover{
                    color: $color-blue;
                }
            }
        }




        &__button-block{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
        }

        &__close-button{
            width: 100%;
            height: $height-button;
            @extend %blueButton;
        }

 

        

    }


}

$timeAnim: .35s;
$translateY: -50px;

.modalWindowContactsAnim-enter{
    animation: OpenModalAnim $timeAnim;
    .modalWindowContacts{
        animation: OpenModalTransAnim $timeAnim;
    }
}
.modalWindowContactsAnim-enter-active{
    animation: OpenModalAnim $timeAnim;
    .modalWindowContacts{
        animation: OpenModalTransAnim $timeAnim;
    }
}
.modalWindowContactsAnim-exit{
    animation: CloseModalAnim $timeAnim;
    .modalWindowContacts{
        animation: CloseModalTransAnim $timeAnim;
    }
    
}
.modalWindowContactsAnim-exit-active{
    animation: CloseModalAnim $timeAnim;
    .modalWindowContacts{
        animation: CloseModalTransAnim $timeAnim;
    }
}


@keyframes OpenModalTransAnim {
    0% {
    transform: translateY($translateY);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes CloseModalTransAnim {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY($translateY);
    }
}

@keyframes OpenModalAnim {
    0% {
    opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes CloseModalAnim {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.finderUser{
    position: relative;
    &__block{
        position: relative;
        margin-top: 5px;
        max-width: 300px;
    }
    &__input{
        width: 100%;
        background-color: $body-color;
        border-radius: 10px;
        border: 1px solid $color-blue;
        height: 44px;
        padding: 8px 45px 8px 8px;
        transition: .2s;
        &:hover{
            box-shadow:  0px 0px 5px 1px $color-blue; 
        }
        &:focus{
            box-shadow: 0 0 5px 1px $color-blue;
            outline: 0 !important;
          }
        &:active{
            box-shadow: 0 0 0 2px transparent !important;
            outline: 0 !important;
        }
    }
    &__buttonBlock{
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 40px;  
    }
    &__button{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        background: transparent;
        outline: 0;
        border: 0;
        transition: .2s;
        &:hover{
            box-shadow: 0 !important;
            outline: 0 !important;
        }
        &:focus{
            box-shadow: 0 !important;
            outline: 0 !important;
          }
        &:active{
            box-shadow: 0 !important;
            outline: 0 !important;
        }

        & svg{
            width: 15px;
            height: 15px;
            min-height: 15px;
            min-width: 15px;
            fill: $color-blue;
        }
    }

    &__title{
        font-weight: bold;
    }

    &_error{
        // box-shadow: 0 0 0 2px rgba($color-red, 1);
        border-radius: $border-radius;
        animation: form-error__FF__box 2s forwards;
        &::after{
            content: attr(data-error);
            position: absolute;
            // width: 35%;
            height: 100%;
            background-color: rgba($color-red, 1);
            top: 0;
            right: 0;
            pointer-events: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            overflow: hidden;
            white-space: nowrap;
            z-index: 10;
            border-radius: 0 $border-radius $border-radius 0;
            // white-space: pre-wrap;
            color: $color-white;
            animation: form-error__FF__text 2s forwards;
        }
        @keyframes form-error__FF__text {
            0%{
                width: 0;
            }
            10% {
                width: 50%;
              }
            90%{
                width: 50%;
            }
            100%{
                width: 0;
            }
        }
        @keyframes form-error__FF__box {
            0%{
                box-shadow: 0 0 2 px 2px rgba($color-red, 0);
            }
            10% {
                box-shadow: 0 0 2px 2px rgba($color-red, 1);
              }
            100%{
                box-shadow: 0 0 2px 2px rgba($color-red, 1);
            }
            // 100%{
            //     box-shadow: 0 0 0 2px rgba($color-red, 0);
            // }
        }
    }


}
@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.cardUser{
    border: 1px solid;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    max-width: 307.5px;
    margin: 15px 15px 0 0;
    overflow: hidden;

    &__dataAndRemove{
        display: flex;
        justify-content: space-between;
    }

    &__buttonAndCountPhoto{
        display: flex;
        justify-content: space-between;
    }
    &__email{
        font-size: 18px;
        font-weight: 600;
    }
    &__buttonShow{
        width: 30px;
        min-width: 30px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        & svg{
            transition: .2s;
            width: 20px;
            height: 20px;
            fill: $color-blue;
        }
    }
    &__emailAndpassword, &__buttonAndCountPhoto{
        margin-top: 15px;
    }
    &__emailAndpassword{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__remove{
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        & svg{
            width: 15px;
            height: 15px;
            min-height: 15px;
            min-width: 15px;
            fill: $color-red;
        }
    }
    &__button-and-count-block{
        margin-top: 15px;
        display: flex;
        align-items: center;
    }
    &__countPhoto{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        margin-left: 15px;
        & span{
            font-size: 24px;
            font-weight: bold;
        }
    }
    &__buttonPhoto{
        height: $height-button;
        width: 100%;
        @extend %blueButton;
        &_disabled{
            opacity: .2;
            cursor: default;
            user-select: none;
            pointer-events: none;
        }
    }
    &__nameDealer{
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -17px;
        margin-top: 15px;
        background-color: rgba($color: black, $alpha: .2);
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
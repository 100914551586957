@import "../../../global-variable.scss";
@import "../../../patterns.scss";

.modalWindowAccessDataBG{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.95);
    z-index: 2031;
    .modalWindowAccessDataBG__close{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
        // background-color: $color-red;
        z-index: 2030;
        &:hover{
            .modalWindowAccessDataBG__close__button{
                opacity: 1;
            }
        }
        .modalWindowAccessDataBG__close__button{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 15px;
            fill: $color-white;
            opacity: .5;
            margin: 15px;
            transition: .2s;
        }
    }

    .modalWindowAccessData{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // position: relative;
        // flex-wrap: wrap;
        max-width: 580px;
        width: 580px;
        // height: 280px;
        padding: 15px;
        margin: 15px;
        z-index: 2032;
        background-color: $body-color;
        border-radius: $border-radius;
        text-align: center;

        position: relative;
        overflow: hidden;

        &__header-name{
            font-weight: bold;
        }

        &__email-password-block{
            margin-top: 25px;
        }

        &__email{
            font-size: 20px;
            font-weight: 600;
        }

        &__password{
            font-size: 26px;
            font-weight: 600;
        }

        &__change-button{
            margin-top: 15px;
            width: 100%;
            height: $height-button;
            @extend %borderBlueButton;
        }

        &__close-button{
            margin-top: 15px;
            width: 100%;
            height: $height-button;
            @extend %blueButton;
        }
        
        

    }


}

$timeAnim: .35s;
$translateY: -50px;

.modalWindowAccessDataAnim-enter{
    animation: OpenModalAnim $timeAnim;
    .modalWindowAccessData{
        animation: OpenModalTransAnim $timeAnim;
    }
}
.modalWindowAccessDataAnim-enter-active{
    animation: OpenModalAnim $timeAnim;
    .modalWindowAccessData{
        animation: OpenModalTransAnim $timeAnim;
    }
}
.modalWindowAccessDataAnim-exit{
    animation: CloseModalAnim $timeAnim;
    .modalWindowAccessData{
        animation: CloseModalTransAnim $timeAnim;
    }
    
}
.modalWindowAccessDataAnim-exit-active{
    animation: CloseModalAnim $timeAnim;
    .modalWindowAccessData{
        animation: CloseModalTransAnim $timeAnim;
    }
}


@keyframes OpenModalTransAnim {
    0% {
    transform: translateY($translateY);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes CloseModalTransAnim {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY($translateY);
    }
}

@keyframes OpenModalAnim {
    0% {
    opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes CloseModalAnim {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}